import React from "react"
import PropTypes from "prop-types"
import styles from "./SelectRate.module.scss"

const UnavailableRatesCard = ({ type = "unavailable", text }) => {
  let headline = "Sold Out"
  let message =
    "There are no parking spots available for reservation. Please select another date or check back later for availability."

  if (type === "notification") {
    headline = ""
    message = text
  }

  return (
    <div className={styles.emptyRatesWrapper}>
      <div className={styles.emptyRatesHeadline}>{headline}</div>
      <div>{message}</div>
    </div>
  )
}

UnavailableRatesCard.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string
}

export default UnavailableRatesCard
