export const AUTH_KEY = "oaTag"
export const PROMO_CODE_KEY = "promoCode"
export const AUTH_REDIRECT_URL_KEY = "authSuccessUrl"

export const BEHAVIOUR_TYPE = {
  purchase: "PURCHASE",
  notification: "NOTIFICATION"
}

export const STEPS = {
  zone: "select-zone",
  date: "select-date",
  rate: "select-rate"
}

export const RATE_AVAILABILITY_COLORS = {
  soldOut: "#F7CDD4",
  available: "#31C81933",
  unavailable: "#f0f0f0"
}
